.ink-resources {
  height: 500px;

  &__about-view {
    
    .sidemenu {
      border-radius: 6px;
      overflow: hidden;
      
      .item .title {
        background: #7e4e7f;
        color: #FFF;
        font-weight: bold;
        font-size: 14px;
        line-height: 100%;
        padding: 10px 20px;
        display: block;
      }
      
      .item a {
        display: block;
        text-decoration: none;
        cursor: pointer;
        font-size: 12px;
        line-height: 16px;
        padding: 10px 20px 0 20px;
        color: #26718d;
        background: #ebebeb;
      }
      
      .item a.extra {
        padding-bottom: 15px;
      }
    }
  
    .principles-list {
      margin: 0;
      padding: 0;
      list-style: none;
      
      li {
        margin-bottom: 15px;
        font-size: 13px;
        line-height: 24px;
      }
    }
  
    .press {
      ul {
        margin: 0;
      }
    
      li img {
        float: left;
        margin-right: 20px;
      }
    }
  }

  .offset-9 {
    margin-left: 75%,
  }

  ol {
    padding-left: 40px;
    padding-right: 0px;
    margin-bottom: 10px;
    height: 744px;
  }
}

.google-sheet {
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
}