.user-name {
  padding: 0 0 0 10px;
  color: rgb(85, 85, 85);
}

@media (min-width: 1200px) {
  img.nav-profile-image {
    position: absolute;
  }
  .user-name {
    max-width: 94px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-left: 20px;
  }
}

#user-dropdown::after,
#group-dropdown::after,
#workshop-dropdown::after,
#resources-dropdown::after {
  display: none;
}

.dropdown-item a {
  color: rgb(85, 85, 85);
  text-decoration: none;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffffff !important;
}

.mr-auto > .nav-link,
#group-dropdown,
#workshop-dropdown,
#resources-dropdown {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 100;
  margin-right: 0;
  margin-top: 0;
  text-transform: uppercase;
  white-space: nowrap;
}
.line {
  height: 23px;
  width: 91% !important;
  background: url(https://www.inkedvoices.com/static/img/line.png) center left;
  margin: 0 auto 10px auto !important;
  display: block;
  padding: 0 !important;
}

@media (min-width: 1200px) {
  .container {
    width: 1070px !important;
  }
}

.navbar-nav {
  margin-top: 15px;
  margin-bottom: 15px !important;
  .btn {
    padding: 5px 30px !important;
    margin-left: 4px;
    font-size: 14px;
    font-family: "Open Sans";
    line-height: 20px;
  }
  a {
    padding: 15px 15px 15px 15px !important;
    white-space: normal;
  }
  .nav-item.show.dropdown {
    background-color: rgb(231, 231, 231);
    color: #555;
    .dropdown-item {
      padding-top: 0 !important;
      color: rgb(51, 51, 51);
      width: 220px;
    }
    .dropdown-item:hover {
      background-color: rgba($color: #ffffff, $alpha: 0);
      color: #2b967c;
    }
  }
}
