.detail-page {
    h1 {
        font-weight: 700;
        font-size: 36px;
        line-height: 51px;
    }

    h3,
    h3 p,
    h3 div,
    h3 p span {
        font-family: "lato", sans-serif !important;
        font-weight: 800 !important;
        font-style: italic !important;
        font-size: 20px !important;
        line-height: 30px !important;
        color: rgb(85, 85, 85) !important;
    }

    h3.date {
        font-style: normal !important;
        font-size: 18px !important;
        line-height: 32px !important;
    }

    h4.grey {
        color: #555555;
        font-weight: 700;
        font-size: 18px;
        line-height: 27.5px;
    }

    .left-column,
    .right-column {
        margin-top: 10px;
    }
}