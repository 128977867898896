.filters-list {
    h4 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;

        text-transform: uppercase;

        color: #000000;
    }

    .button-list {
        line-height: 40px;

        button {
            margin-right: 3px;
        }

        button.purple,
        .btn.purple:hover,
        .btn.purple:focus {
            background: #7e4e7f !important;
            box-shadow: none;
            font-size: 15px;
            line-height: 27px;
            padding: 3px 8px;
        }

        button.purple.inactive,
        button.purple.inactive:hover,
        button.purple.inactive:focus {
            background: #D9D9D9 !important;
            color: #555555;
            box-shadow: none;
            font-size: 15px;
            line-height: 27px;
            padding: 3px 8px;
        }
    }
}