.archive-detail {
  padding-bottom: 20px;

  .additional-material-item {
    .icon {
      display: inline-block;
      width: 31px;
      height: 31px;
      background-size: contain;
      margin-top: 4px;
    }

    .paper-icon {
      background-image: url(https://www.inkedvoices.com/static/img/Article.png);
    }

    .camera-icon {
      background-image: url(https://www.inkedvoices.com/static/img/Webcast.png);
    }

    .mic-icon {
      background-image: url(https://www.inkedvoices.com/static/img/Podcast.png);
    }

    .title-wrapper {
      vertical-align: middle;
      height: 40px;
      position: relative;

      .title {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #000000;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  .webcast-container {
    border-radius: 6px;
    overflow: hidden;
    background: #ddd;
    /*height: 500px;*/
    margin-bottom: 20px;

    div,
    iframe {
      width: 100%;
      height: 500px;
    }
  }

  .rounded-gray-box h6 {
    font-size: 16px;
  }

  .grey-message-box {
    width: 100%;
    height: 615px;
    background: #f1f1f1;
    position: relative;

    .vertical-center {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      line-height: 15px;
    }
  }
}

.delete-button {
  background: url(https://www.inkedvoices.com/static/img/sprite.png) -550px -250px;
  width: 18px;
  height: 22px;
  display: inline-block;
}
