img.ellipsis_icon {
    width: 2em;
    height: auto;

}

.dashboard{
    .nav-tabs{
        a{
            text-decoration: none;
            background-color: #8c7a8b;
            color: #ffffff;
            }
    }
    .current-tab{
        .project-header{
            background: none;
            border-bottom: solid 2px #efefef;
            font-size: 15px;
            font-family: arial, verdana, sans-serif;
            .icon-img {
                width: 20px;
                padding-left: 1px;
                margin: 2px 0;
            }
            .project-option{
                a,
                a:hover,
                button,
                button:hover {
                    color: #005b7f;
                    margin-left: 16px;
                    text-decoration: none;
                    background-color: transparent !important;
                    border: none;
                }
            }
        }
        .card-body{
            padding: 0;
            .row{
                margin: 0;
                border-bottom: solid 2px #efefef;
                background-color: #f9f9f9;
                padding: 8px;
                line-height: 1.42857143;
                vertical-align: top;
                border-top: 1px solid #ddd;
            }
            .row:hover{
                background: #ebebeb;
            }
            .document-title.row{
                padding-left: 1.9em;
            }
            .document-detail.row{
                padding: 8px 8px 8px 50px;
            }        }
    }
    .modal_table_submit_doc{
        td {
            vertical-align: middle !important;
        }
    }
}
.modal.show .modal-dialog {
    min-width: 40vw;

    .sm-text.membership-balance{
        font-family: 'Open Sans', sans-serif !important;
        font-size: 11px !important;
        color: #707070 !important;
    }
    .sm-text.membership-balance.error{
        color: red !important;
    }
}