html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Open Sans";
  font-size: 15px;
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 1070px;
}

a {
  color: #0a5c7d;
  text-decoration: none !important;
  &:hover {
    color: #0a5c7d;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 36px;
  line-height: 51px;
  color: #7f4f80;
}

h1 a {
  text-decoration: none;
  color: #663366;
  &:hover {
    color: #663366;
  }
}

h2 {
  font-family: "Lato", sans-serif;
  font-weight: 700 !important;
  font-style: italic;
  font-size: 20px !important;
  color: #555;
  line-height: 35px;
}

h3 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #555;
}

h4 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #7f4f80;
  line-height: 27.5px;
  font-weight: 400;
  text-transform: uppercase;
}

h4 a {
  text-decoration: none;
  color: #707070;
}

h5 {
  font-family: "Lato", sans-serif;
  font-size: 14px !important;
  color: #362e35;
  line-height: 26px !important;
  font-weight: 700 !important;
}

p {
  font-family: "Open Sans", sans-serif;
  color: #000;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 27px;
}

ul {
  margin-left: 4%;
}

li {
  font-family: "Open Sans", sans-serif;
  color: #000;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 27px;
}

p.p-small {
  font-family: "Open Sans", sans-serif;
  color: #000;
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 27px;
}

p.p-subtitle {
  font-family: "Open Sans", sans-serif;
  color: #000;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 27px;
}

span.p-small {
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 13px;
  line-height: 27px;
}

h1.larger {
  font-size: 42px;
  line-height: 40px;
  font-weight: 700;
  text-transform: none;
  margin-bottom: 50px;
}

h1.largest {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  text-transform: none;
  margin-bottom: 50px;
}

h1,
h3 {
  margin-bottom: 10px;
}

section {
  padding-bottom: 55px;
}

@media (min-width: 1200px) {
  .container {
    width: 1070px;
  }
}

@media (min-width: 2140px) {
  .container {
    width: 1570px !important;
  }
}

.btn {
  text-decoration: none;
  background-color: #7e4e7f;
  padding: 5px 30px;
  color: #ffffff;
  text-shadow: none;
  border: none;
  font-size: 14px;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-large {
  padding: 10px 35px;
  font-weight: 700;
}

.btn.btn-small {
  padding: 5px 10px;
  font-size: 0.875rem;
}

.btn-p {
  text-decoration: none;
  color: #ffffff;
  background-color: #7e4e7f;
  padding: 5px 30px;
}

.btn:hover,
.btn:focus {
  background-color: #9e7aa0;
  color: #ffffff;
}

.btn.cancel {
  background-color: #afafaf;
  color: #ffffff;
  border: none;
}

.btn.cancel:hover,
.btn.cancel:focus {
  background-color: #707070 !important;
}

.disabled-btn:hover {
  background-color: #7f4c76;
}

.btn.purple,
.btn-primary {
  background: #7e4e7f;
  color: #fff;
  text-shadow: none;
  border: none;
}

.btn-primary:disabled {
  background-color: #7e4e7f99 !important;
  cursor: not-allowed !important;
}

.btn.transparent {
  background-color: transparent !important;
  color: #7e4e7f;
  border: none;
}

.btn.transparent:hover {
  background-color: transparent !important;
  color: #7e4e7f99 !important;
}

.btn.purple:hover,
.btn.purple:focus,
.btn.purple:hover,
.btn-primary:hover {
  background: #9e7aa0 !important;
  color: #fff;
  text-shadow: none;
  border: none;
}

@media only screen and (max-device-width: 480px) {
  .btn.purple,
  .btn.purple:focus,
  .btn.purple:hover,
  .btn.purple:active,
  .btn.purple:visited,
  .btn-primary,
  .btn-primary:focus,
  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:visited {
    background: #7f4c76 !important;
  }
}

.btn.yellow {
  background: #fdc52e;
  color: #000;
}

.yellow a {
  color: #333333;
}

.btn.yellow:hover,
.btn.yellow:focus {
  background: #fdc52e !important;
  opacity: 0.8 !important;
}

.spacer {
  flex-grow: 1;
}

.fit-container {
  max-width: fit-content;
}

.pull-right {
  float: right !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.sm-text {
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #707070;
}

div.fade.modal.show {
  div.modal-dialog {
    div.modal-content {
      background-color: #f4f4f4;
      padding: 5% 5%;
      div.modal-header {
        .modal-title {
          margin-bottom: 10px;
          font-family: "Lato", sans-serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 32px;
          color: #555;
        }
        border-bottom: 0;
      }
      div.modal-body {
        .quill {
          background-color: #fff;
          .ql-editor {
            min-height: 200px;
          }
        }
        .quill.error {
          .ql-container,
          .ql-toolbar {
            border-color: #dc3545;
          }
        }
        div.groups-available {
          width: 100%;
          max-height: 300px;
          overflow-y: scroll;
          overflow-x: visible;
        }
        table.no-editors {
          width: 100%;
          max-width: 100%;
          div.row {
            div.col-sm-7,
            div.col-sm-3,
            div.col-sm-2 {
              font-family: arial, verdana, sans-serif;
              font-size: 15px;
              padding: 8px;
              line-height: 1.42857143;
              vertical-align: top;
              border-top: 1px solid #ddd;
              background-color: rgb(249, 249, 249);
              color: #333;
            }
          }
        }
        div.no-margin.form-group {
          margin-bottom: 0 !important;
        }
      }
      div.modal-footer {
        border-top: 0;
      }
      button.green.btn {
        background-color: rgb(131, 162, 76);
        border: 0;
      }
      button.green.btn:hover {
        background-color: rgb(180, 198, 131) !important;
      }
      .btn {
        margin: 0.25rem;
      }
      p.upload-text {
        font-family: "Open Sans", sans-serif;
        font-size: 11px;
        color: #707070;
        line-height: 1.42857143;
      }
    }
  }
}
.italic {
  font-style: italic;
}

.error-text {
  color: red;
}

/* import bootstrap to set changes */
// @import "~bootstrap/scss/bootstrap";
.social {
  width: 57px;
  height: 57px;
  display: inline-block;
  margin: 0 5px 5px 0;
  background: url(https://www.inkedvoices.com/static/img/sprite.png) 0 -200px;
}

.social.ico-fb {
  background-position: 0 -200px;
}

.social.ico-fb:hover {
  background-position: 0 -300px;
}

.social.ico-tw {
  background-position: -100px -200px;
}

.social.ico-tw:hover {
  background-position: -100px -300px;
}

.social.ico-gr {
  background-position: -200px -200px;
}

.social.ico-gr:hover {
  background-position: -200px -300px;
}

.social.ico-ig {
  background-position: -300px -200px;
}

.social.ico-ig:hover {
  background-position: -300px -300px;
}

.social.ico-li {
  background-position: -400px -200px;
}

.social.ico-li:hover {
  background-position: -400px -300px;
}

.messages-number {
  position: relative;
  top: -27px;
  left: 17px;
  z-index: 100;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  color: white;
  background-color: red;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

// intro.js styles
.introjs-tooltip-title {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  color: #7f4f80;
  line-height: 27.5px;
  font-weight: 400 !important;
  text-transform: uppercase;
}

.introjs-highlight {
  box-shadow: rgba(33, 33, 33, 0.2) 0px 0px 1px 1px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
.introjs-button {
  font: 11px / normal sans-serif !important;
  padding: 0.3em 0.8em !important;
}

.introjs-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(158, 158, 158, 0.2) !important;
}

.introjs-skipbutton {
  width: 30px !important;
  height: 30px !important;
  top: -8px !important;
}

.introjs-tooltiptext {
  padding-top: 5px !important;
  padding-bottom: 0 !important;
  font-size: 15px;
  font-family: arial, verdana, sans-serif;
}
.introjs-bullets {
  padding-top: 5px !important;
}

/* Adding tailwind like classes */
.pointer {
  cursor: pointer;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inline-block {
  display: inline-block;
}
.inline-flex {
  display: inline-flex;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-col {
  flex-direction: column;
}
.p-0 {
  padding: 0;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.text-md {
  font-size: 1.125rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-xs {
  font-size: 0.75rem;
}
.text-purple {
  color: #7e4e7f;
}
.text-black {
  color: #000;
}
.text-muted-foreground {
  color: #707070;
}
.overflow-auto {
  overflow: auto;
}
.caption-bottom {
  caption-side: bottom;
}
.border {
  border-width: 1px;
}
.border-b {
  border-bottom: solid #c7c7c768 1px;
}
.border-t {
  border-top: solid #c7c7c768 1px;
}
.border-l {
  border-left: solid #c7c7c768 1px;
}
.border-r {
  border-right: solid #c7c7c768 1px;
}
.border-input {
  border-color: hsl(var(--input));
}
.transition-colors {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.top-2\.5 {
  top: 0.625rem;
}
.left-2\.5 {
  left: 0.625rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-full {
  height: 100%;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-10 {
  width: 2.5rem;
}
.w-64 {
  width: 16rem;
}
.w-full {
  width: 100%;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-2\.5 {
  padding-left: 0.625rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-8 {
  margin-left: 2rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-8 {
  margin-right: 2rem;
}
.text-green-500 {
  color: rgb(34 197 94 / 500);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.align-middle {
  vertical-align: middle;
}
.font-light {
  font-weight: 300;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
.bg-muted {
  background-color: #c7c7c768;
}
.bg-purple {
  background: #7e4e7f !important;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.flex-row {
  flex-direction: row;
}
.gap-1 {
  gap: 0.25rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.rounded-md {
  border-radius: 6px;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.cursor-pointer {
  cursor: pointer;
}

// annotator classes

.annotator-adder,
.annotator-outer,
.annotator-widget,
.annotator-notice {
  border-color: #999 !important;
}

.annotator-user {
  font-weight: bold !important;
  color: #432038 !important;
}

.annotator-editor .annotator-item,
.annotator-viewer div {
  border: none !important;
}

.annotator-viewer div:first-of-type,
.annotator-editor .annotator-item:first-child textarea {
  font-style: normal;
}

.annotator-editor .annotator-item:first-child textarea {
  max-height: 500px;
  max-width: 500px;
}

.annotated_content {
  padding-bottom: 70px;
}

.annotated_content p {
  white-space: pre-wrap;
}

#annotated_content .annotator-hl {
  background: rgba(134, 93, 133, 0.4);
  display: inline;
  color: #222;
}

#annotated_content .annotator-editor a,
#annotated_content .annotator-filter .annotator-filter-property label {
  background-color: #83a24c;
  background-image: none;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  text-shadow: none;
  border-color: #222;
}

#annotated_content .annotator-editor a:hover,
#annotated_content .annotator-editor a:focus,
#annotated_content .annotator-filter .annotator-filter-active label,
#annotated_content .annotator-filter .annotator-filter-navigation button:hover {
  background-color: #432038;
  background-image: none;
  text-shadow: none;
  border-color: #222;
}

.annotator-editor a.annotator-cancel {
  background: #d5d2ca !important;
  background-image: none;
  color: #222 !important;
  float: right;
  margin-left: 5px;
  padding-left: 22px;
  padding-right: 22px;
  text-shadow: none;
  border: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
}

.annotator-editor a.annotator-cancel:hover {
  background: #cdcbc7 !important;
  background-image: none;
  text-shadow: none !important;
  font-weight: normal !important;
  border: 0;
}
.annotator-editor a.annotator-save,
.annotator-editor a.annotator-focus.annotator-save {
  background-image: none;
  background-color: #83a24c;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-decoration: none;
  text-shadow: none;
  padding-left: 22px;
  padding-right: 22px;
  border: 0;
}

.annotator-editor a.annotator-delete,
.annotator-editor a.annotator-focus.annotator-delete {
  background-image: none;
  background-color: #d05454;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-decoration: none;
  text-shadow: none;
  padding-left: 22px;
  padding-right: 22px;
  border: 0;
}

.annotator-editor a.annotator-delete:after {
  display: none;
}

.annotator-editor a.annotator-save:hover {
  background-color: #82a24cc2;
  background-image: none;
  text-shadow: none;
  border: 0;
}

.annotator-editor a.annotator-save::after,
.annotator-editor a.annotator-cancel::after {
  display: none;
}

#annotated_content .annotator-filter,
#annotated_content .annotator-filter .annotator-filter-navigation button,
#annotated_content .annotator-editor .annotator-controls {
  background: #fff;
}

#annotated_content .annotator-editor a.annotator-save:hover::after,
#annotated_content .annotator-editor a.annotator-save:focus::after,
#annotated_content .annotator-editor a.annotator-save.annotator-focus::after {
  background: none;
}

#annotated_content .annotator-editor a::after {
  background: none;
}

.annotator-adder {
  background-image: url("https://inkedvoices.com/static/css/images/index.png") !important;
  background-repeat: no-repeat;
}

body.read-only-annotations {
  .annotator-adder {
    display: none !important;
  }
}

.annotator-editor .annotator-item input,
.annotator-editor .annotator-item textarea {
  background: #fff !important;
}

.annotator-widget::after {
  left: 50% !important;
  margin-left: -7px !important;
}

form.annotator-widget,
ul.annotator-widget.annotator-listing {
  margin-left: -118px;
}

.annotator-marginviewer-element {
  position: relative;
  cursor: pointer;
}

.margin-annotator-container {
  height: 100%;
  min-height: 100%;
}

.col-md-3.margin-annotator-container {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.text-and-annotations-wraper {
  margin-left: -100px;
  padding-left: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.txt-container {
  border: 1px solid #ccc;
  padding: 40px;
  word-wrap: break-word;
}

.annotator-widget.annotator-listing {
  display: none;
}
@media only screen and (max-device-width: 480px) {
  ul.annotator-widget.annotator-listing {
    display: block;
    min-width: 60px;
    margin-left: 0;
    li {
      div {
        text-align: center;
      }
    }
  }
}

.margin-annotator-wrapper {
  overflow-y: visible;
  overflow-x: visible;
}

.submission-notes {
  width: 100%;
  border-left: 5px solid #663366;
  padding-left: 20px;
  margin-bottom: 20px;
}

.btn.green,
td .btn.green {
  background: #83a24c;
  color: #fff;
}

.btn.blue,
td .btn.blue {
  background: #0a5c7d;
  color: #fff;
  font-size: 11.5px;
  padding: 4px 10px;
}
td a {
  color: #222;
  text-decoration: none;
}

.ico-help {
  top: 5px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: url(https://www.inkedvoices.com/static/img/sprite.png) -500px -150px;
  display: inline-block;
  position: relative;
  right: 0;
}

.icon-open {
  background: url(https://www.inkedvoices.com/static/img/sprite.png) -550px -100px;
  width: 23px;
  height: 23px;
  margin: 0 0 0 10px;
  display: inline-block;
}

.icon-close {
  background: url(https://www.inkedvoices.com/static/img/sprite.png) -550px -150px;
  width: 23px;
  height: 23px;
  margin: 0 0 0 10px;
  display: inline-block;
}

.form-control:focus {
  color: #212529;
  background-color: none;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.plus-icon {
  display: inline-block;
  vertical-align: top;
  background: url(https://www.inkedvoices.com/static/img/sprite.png) no-repeat -550px -100px;
  width: 23px;
  height: 23px;
}

.underline-link {
  color: #154f66;
  text-decoration: underline;
  text-transform: none;
  font-size: 15px;
  line-height: 22px;
}

.textarea-holder {
  border: 2px solid #efefef !important;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  textarea {
    display: block;
    width: 100%;
    padding: 0;
    border: none;
  }
  textarea:focus-visible {
    outline: none;
  }
}

.group-cog-image {
  width: 20px;
  cursor: pointer;
  margin-left: 4px;
}

.react-datepicker-wrapper,
.due-date-picker {
  width: 100%;
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.nav-tabs > a {
  margin-right: 2px;
}

.btn:focus {
  box-shadow: none;
}

tr.no-zebra {
  --bs-table-accent-bg: none !important;
  background: none !important;
  &:hover {
    background: #ebebeb !important;
  }
}

.modal-body {
  font-family: "Open Sans", sans-serif;
}

.quick-submission-button {
  margin-top: -25px;
}

@media (max-width: 480px) {
  .quick-submission-button {
    margin-top: -70px;
  }
  #critique-panel {
    padding-top: 20px;
  }
  .nav.nav-tabs {
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

@media (max-width: 768px) {
  .annotator-editor .annotator-widget:after {
    display: none;
  }

  ul.annotator-listing {
    li:nth-child(2) {
      display: none;
    }
  }
}

.annotator-adder.annotator-adder-mobile {
  background-image: url("https://www.inkedvoices.com/static/css/images/index-2.png") !important;
  background-repeat: no-repeat;
}

.annotated_content img {
  width: 100%;
  object-fit: cover;
}
