.inbox, .view-message-container {
  padding-top: 20px;
  padding-bottom: 55px;
  .sidebar {
    div,
    button {
      margin-bottom: 15px;
    }
    a {
      color: #0a5c7d;
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      line-height: 20px;
    }
  }
  table {
    tr {
      height: 43.2px;
      min-height: 43.2px;
    }
    tr.new {
      font-weight: bold;
      background-color: rgba(32, 133, 141, 0.2);
    }
    th {
      background: none !important;
      color: #663366;
      text-transform: uppercase;
      font-size: 18px;
      border-bottom: solid 1px #663366;
      font-family: "Open Sans";
    }
    a {
      color: #222;
    }
    .sm-text {
      line-height: 24px;
    }
    .text-block {
      margin-top: 5px;
    }
  }
  .no-messages {
    text-align: center;
    line-height: 43px;
  }
}
