.custom-close-button .close {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  opacity: 1;
}

.custom-close-button .close:hover {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  opacity: 0.7;
}