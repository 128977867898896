.dashboard{
  #notes-title {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-style: italic;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
    img {
      width: 20px;
      margin-left: 20%;
    }
  }
  .note{
    border-bottom: 2px solid #efefef;
    margin-top: 10px;
    padding-bottom: 15px;
    .sub-title.p-small{
      line-height: 12px;
    }
    .note-date {
      margin-top: -20px;
      margin-bottom: 10px;
    }
    .ico.edit {
      background-image: url(https://www.inkedvoices.com/static/img/sprite.png);
      background-position: 0 -400px;
      margin: 0 3px 0 0;
    }
    .ico.delete {
      background-image: url(https://www.inkedvoices.com/static/img/sprite.png);
      background-position: -50px -400px;
      float: right;
    }
    a.ico {
      width: 32px;
      height: 28px;
      display: inline-block;
      padding: 0;
    }
    .edit {
      color: #695168;
      float: right;
      padding: 5px 10px 0 0;
      text-decoration: underline;
    }
    img {
      width: 16px;
    }
  }
  .note:last-child {
    border: none;
  }
}
