.ink-message-to-group{
    .textarea-holder, .input-holder {
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 3px;
        background: #efefef;
    }
    .textarea-holder textarea {
        background: #efefef;
        padding: 0;
        border: 0;
        margin: 0;
        width: 100%;
        height: 70px;
        resize: none;
    }
    .button-holder {
        text-align: right;
        margin-bottom: 15px;
    }
    .btn.purple, .btn-primary {
        background: #7e4e7f;
        color: #fff;
        text-shadow: none;
        border: none;
    }
    .btn {
        text-decoration: none;
        background-color: #7f4c76;
        padding: 5px 30px;
        color: #ffffff;
        font-size: 14px;
    }
}