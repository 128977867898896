.group-info {
  line-height: 16px;
  padding: 20px !important;
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  min-height: 250px;
  max-height: 250px;
  margin-right: 20px;
  vertical-align: top;
  overflow: auto;
  
  .text-center {
    margin-bottom: 10px;
  }

  p {
    line-height: inherit;
  }

  img {
    max-width: 75px;
    max-height: 75px;
  }

  h5 {
    line-height: normal;
  }
  .btn-container {
    width: 100%;
    margin-bottom: 3px;
  }
  
  .btn {
    min-width: 100px;
    padding: 5px 10px;
    display: inline-block;
  }

  .group-profile-picture {
    width: 75px;
    height: 75px;
    background: url(../../../../assets/bg-group-profile-picture.png) 50% 50% no-repeat;
    background-size: cover;
  }

  .italic {
    font: bold 12px 'Open Sans';
    font-style: italic;
    color: #432038;
  }
}

.group-footer {
  color: #695168;
  padding: 15px 0 0 0;
  position: absolute;
  bottom: 0;
  min-height: 80px;
}

.text.p-small.group-description {
  max-height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  margin-bottom: 15px;
  font-size: 15px;
  line-height: 27px;
}

.ink-footer li {
  line-height: 18px;
}

li.groups-result {
  width: 30%;
  display: inline-block;
  border: 2px solid #ebebeb;
  border-radius: 4px;
  margin: 0 4px 12px !important;
  vertical-align: top !important;
  min-height: 350px;
  max-height: 350px;
  position: relative;
}

h5, h3, h2, h1, .pro-header h4 {
  margin-bottom: 10px;
}

.btn {
  margin-right: 3px;
}

.btn:last-child {
  margin-right: 0;
}

.btn.blue {
  font-size: 14px;
}