.iv-alert {
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.alert-success {
    color: #fff;
    background-color: #83a24c;
    border-color: #c3e6cb;
  }

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    opacity: 0.5;
    text-shadow: 0 1px 0 #fff;
  }

  .close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
  }

  .close:focus {
    outline: none;
    box-shadow: none;
  }
}