.ink-invite-form{
    .select__control{
        background: #efefef !important;
        border: 0 !important;
        overflow: hidden !important;
    }
    .select__multi-value{
        color: #efede9 !important;
        background: #005b7f !important;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }
    .select__multi-value__label{
        color: #efede9 !important;
    }
    .select__control--is-focused{
        border: 0 !important;
        box-shadow: none !important;
    }
}