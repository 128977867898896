.ink-invite-form{
    h5 {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        color: #362e35;
        line-height: 26px;
        font-weight: 700;
    }
    .textarea-holder, .input-holder {
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 3px;
        background: #efefef;
    }

    .button-holder {
        text-align: right;
        margin-bottom: 15px;
    }

    .textarea-holder textarea {
        background: #efefef;
        padding: 0;
        border: 0;
        margin: 0;
        width: 100%;
        height: 70px;
        resize: none;
    }

    .input-holder input {
        background: #efefef;
        margin: 0;
        border: 0;
        padding: 0;
        min-width: 100%;
        outline: none;
        box-shadow: none;
    }
}