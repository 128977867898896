.pill-wrapper {
    background-color: #0A5C7D;
    border-radius: 5px;
    padding: 5px 10px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 26px;

    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #F1F1F1;
}