@import "styles/variables";


.event-detail {
    .main-content {
        padding-bottom: 20px;
    }

    img.event-img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        margin-top: 10px;
        height: auto;
        // margin-left: auto;
        // width: 152px;
        // height: 152px;
        // height: 100%;
        // max-width: 100%;
        // max-height: 100%;
    }

    ul {
        margin-left: 0;
        padding-left: 26px;
    }

    li {
        margin: 0;
    }

    p,
    div,
    li {
        font-size: 15px;
        line-height: 20px;
    }

    .note-subtitle {
        font-weight: 700;
    }

    .closed-event,
    .closed-event:hover {
        background-color: #D9D9D9;
        cursor: default;
    }

    .register-button-wrapper p {
        margin-top: 5px;
    }

    .facilitator {
        font-weight: 700;
    }

    button.green {
        background: #608D83;
        border-width: 0px;
        border-color: #608D83;
    }

    button.green:hover,
    button.green:focus {
        background-color: #798b87 !important;
        color: #ffffff;
    }

    p.note span p:last-child {
        margin-bottom: 0;
    }
}