@import "styles/variables";

#iv_footer.ink-footer {
  background-color: $ink-brand-background;
  padding: 0 !important;
  p {
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-weight: 700 !important;
    font-size: 17px !important;
    font-style: italic;
    line-height: 1.7em;
    margin-bottom: 15px;
  }
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .col-xs-4.col-sm-4 {
    float: left;
  }
  .social-media-icon {
    display: inline-block;
    margin-left: 3px;
  }
  h4 {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 27.5px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
  }
  a:hover {
    font-weight: 500;
  }
  .btn.yellow {
    background: #fdc52e;
    color: #fff;
    font-weight: 600;
    text-shadow: none;
    border: none;
    padding: 0.5em 0;
    width: 72%;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    margin-top: 2px;
  }
  .form-inline {
    .form-control {
      width: 72%;
      margin-right: 2%;
      background-color: #e5dce5;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .sponsor {
    width: 40px;
    height: 40px;
  }
  a:hover,
  a:active {
    text-decoration: none !important;
    font-weight: 600 !important;
    color: #fff;
  }
  .newsletter-link {
    color: #fff;
    font-weight: 600;
  }
  #footer-bottom {
    background-color: #333 !important;
    .container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    #footer-copyright,
    #footer-terms {
      display: inline-block;
      font-family: "Open Sans";
      font-size: 15px;
    }
    #footer-enjoy {
      font-weight: 600;
    }
    #footer-terms {
      text-align: right;
      width: 33.33333333%;
      color: #fff !important;
    }
    #footer-terms a:link,
    #footer-terms a:visited {
      text-decoration: none;
      font-weight: 100;
      color: #fff;
    }
  }
}
