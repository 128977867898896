.ink-profile {
    h3 {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #555;
    }

    h4 {
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        color: #7f4f80;
        line-height: 27.5px;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 5px !important;
    }

    h5 {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        color: #362e35;
        line-height: 26px;
        font-weight: 700;
    }

    h3,
    h2,
    h1,
    .pro-header h4 {
        margin-bottom: 10px;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li.quote {
        list-style: none;
        margin: 0 0 10px;
    }

    li.quote-author {
        list-style: none;
        padding: 0 0 25px 30px;
    }

    ul.organizations li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    ul.organizations p {
        margin-bottom: 4px;
        line-height: 18px;
    }

    .col-md-8 h1 {
        font-family: "Lato", Helvetica, Arial, sans-serif;
        font-size: 26px;
    }

    .searching-group-status {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        font-size: 15px;
        display: inline;
        font-style: italic;
        color: #828282;
    }

    .col-md-8 h3 {
        color: #000000;
    }

    .col-md-8 p {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        line-height: 25px;
    }

    .sidebar .group-info {
        border: 2px solid #efefef;
        border-radius: 3px;
        padding: 8px !important;
        margin-bottom: 3px !important;
        margin-top: 10px;

    }

    .sidebar .group-info h3 {
        margin-top: 0;
        font: 12px 'Open Sans';
    }

    .sidebar .group-info a {
        color: #571a56;
        text-decoration: none;
    }

    .group-info .description p {
        line-height: 16px;
        margin: 0;
    }

    .sidebar .group-info .description {
        margin: 0;
    }

    .profile-info {
        border: 2px solid #efefef;
        border-radius: 3px;
        padding: 20px;
        text-align: center;
        margin-bottom: 5px
    }

    .list-tags li {
        color: #555555;
        background: #e4edf1;
        border-left: 2px solid #005b7f;
        padding: 2px 6px 2px 6px;
        position: relative;
        margin: 5px 10px 5px 0;
        display: inline-block;
    }

    .publications-list li {
        width: 45%;
        margin-top: 0px;
        margin-right: 15px;
        display: inline-block !important;
    }

    .publications-list img {
        float: right;
    }

    .publications-list li h4 {
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 15px;
        font-weight: bold;
        color: #333;
        margin-top: 0px;
    }

    .publications-list a {
        color: #005b7f;
    }

    .profile-view .col-md-8 p {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        line-height: 25px;
    }

    .btn-primary {
        border-color: unset;
    }

    .contact-btn {
        padding: 5px 30px;
        font-size: 1rem;
        background: #0a5c7d;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .contact-btn:focus,
    .contact-btn:hover {
        background: #0e6e95;
        color: #fff;
    }

}

h4 {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color: #7f4f80;
    line-height: 27.5px;
    font-weight: 400;
    text-transform: uppercase;
}

h5 {
    margin-bottom: 0 !important;
}

h4.super-category {
    margin-top: 5px;
    font-size: 15px !important;
}

.modal label {
    font-size: 15px;
    margin-bottom: 0;
}

.modal-body label {
    font-weight: 700;
}

.group h1 {
    font: 700 26px 'Lato';
}

span.last-activity.active {
    color: #1b9e3e;
}

span.last-activity {
    margin-left: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}

.group h1 {
    font: 700 26px 'Lato';
}