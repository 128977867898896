.dashboard {
    .project-actions{
        .btn.list-group-item{
            background-color: rgba(0, 0, 0, 0);
            padding: 5px 30px;
            a.action-button {
                color: #005b7f !important;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.42857143;
                text-align: center;
                white-space: nowrap;
                font-family: 'Open Sans', sans-serif;
            }
            border: none;
        }
        .btn.btn-primary.list-group-item{
            background-color: rgb(126, 78, 127) !important;
            a.action-button{
                color: #fff !important;
            }
        }
    }
    .tab-content {
        .tab-purple {
            background-color: #8c7a8b;
            color: #ffffff;
        }
        
        .tab-pane h2 {
            font: bold 18px 'Lato' !important;
            font-style: italic !important;
            color: #0a5c7d;
        }
        
        .tab-pane#files .blue-bold-link {
            margin: 15px 0 10px;
            display: block
        }
        
        .tab-pane ul {
            list-style: none;
            margin: 0;
            padding: 0
        }
        .no-projects{
            padding: 10px 20px;
        }
        .current-tab,
        .archive-tab {
            .row.project-row{
                border-bottom: solid 2px #efefef;
                padding: 8px;
                border-top: 1px solid #ddd;
                line-height: 36px;
                .document-title{
                    img.icon-img{
                        width: 20px;
                        padding-left: 1px;
                        margin: 2px 0;
                    }
                    background: transparent !important;
                    vertical-align: top;
                    a{
                        color: #333333 !important;
                        text-decoration: none;
                    }
                }
                .action-buttons{
                    text-align: right;
                    button.btn{
                        color: #005b7f;
                        text-decoration: none;
                        font-family: arial, verdana, sans-serif;
                        font-size: 15px;
                        background: transparent !important;
                        border-width: 0 !important;
                    }
                }
            }
            .row.project-row:hover{
                background: #ebebeb !important;
            }
            .row.project-row.inner{
                background-color: #f1f1f1db;
                .document-title{
                    padding-left: 50px !important;
                }
            }
            .row.project-row.document-row{
                .action-buttons{
                    button.btn{
                        background: transparent !important;
                        color: black;
                        text-decoration: none;
                        text-shadow: none;
                        padding: 5px 30px;
                        font-size: 14px;
                        border-color: transparent;
                    }
                    button.btn:focus,
                    button.btn:hover {
                        border-color: #7e4e7f !important;
                        border-width: 1px !important;
                        color: #7e4e7f;
                        text-shadow: 0 0 0 #7e4e7f;
                    }
                }
            }
            .row.project-row.submission-row{
                padding-left: 50px;
                .critique{
                    a.label{
                        background-color: #5f8b80;
                        color: #ffffff;
                        padding: 5px 10px;
                        border-radius: 3px;
                        text-decoration: none;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 11px;
                    }
                    span.italic{
                        font: italic 14px 'Lato';
                    }
                }
            }
            .row.project-row.submission-row.inner{
                padding-left: 70px !important;
            }
        }
    }
}

div.document-options-tooltip{
    border: 1px solid rgba(0,0,0,.15);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    opacity: 1 !important;
    padding: 0 !important;
    .tooltip-inner{
        background-color: #ffffff;
        padding: 0;
        ul{
            padding: 0 !important;
            margin: 0;
            li{
                min-width: 160px;
                text-align: left;
                font-family: 'Open Sans', sans-serif;
                color: #000;
                margin-top: 7px;
                margin-bottom: 7px;
                font-size: 15px;
                line-height: 27px;
                display: list-item;
                text-align: left;            
                list-style: none;
                a{
                    display: block;
                    padding: 3px 20px;
                    clear: both;
                    font-weight: 400;
                    line-height: 1.42857143;
                    color: #005b7f;
                    text-decoration: none;
                }
            }
        }
    }
}