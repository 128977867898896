.annotator-marginviewer-delete {
  width: 14px;
  height: 20px;
  margin: 5px 0 0 3px;
  background: url(https://www.inkedvoices.com/static/img/bin.jpg) no-repeat 0 0;
  opacity: 0;
}

.annotator-marginviewer-element.annotator-marginviewer-selected .annotator-marginviewer-delete,
.annotator-marginviewer-element.annotator-marginviewer-selected .annotator-marginviewer-edit {
  opacity: 1;
  cursor: pointer;
  cursor: hand;
  z-index: 20;
}

.annotator-marginviewer-edit {
  width: 20px;
  height: 20px;
  background: url(https://www.inkedvoices.com/static/img/notepad.png) no-repeat 0 0;
  margin: 5px 0 0 3px;
  opacity: 0;
}

.annotator-marginviewer-element {
  background-color: #fff;
  border: 1px solid #000;
  width: 99%;
  padding: 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.annotator-marginviewer-element.annotator-marginviewer-selected>.arrow {
  top: 28px;
  left: -11px;
  margin-top: -11px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #000;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  bottom: -10px;
  content: " ";
}

.annotator-marginviewer-element.annotator-marginviewer-selected>.arrow:after {
  top: 1px;
  left: 1px;
  margin-top: -11px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgb(255, 255, 255);
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  bottom: -10px;
  content: " ";
}

.annotator-margin-filtered {
  display: none;
}

.annotator-marginviewer-text-wrapper {
  font-size: 11px;
  line-height: 18px;
  font-family: "Open Sans";
  color: #333;
  padding: 5px 5% 5% 10px;
  display: table;
  width: 90%;
  word-break: break-word;
}

.annotator-marginviewer-text .morelink {
  float: right;
}

.annotator-marginviewer-selected.annotator-marginviewer-text {
  padding-top: 5px;
}

.annotator-marginviewer-header {
  border: solid #c5c5c5;
  border-width: 1px 0 0 0;
  background-color: #fff;
  display: table;
  width: 100%;
  padding: 5px 0 5px 0;
}

.annotator-marginviewer-user {
  width: 120px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #663366;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  padding: 0 5px 0 10px;
}

.annotator-marginviewer-date {
  color: #333;
  font-family: "Open sans";
  font-size: 13px;
  line-height: 16px;
  padding: 0 5px 0 0;
}

.annotator-marginviewer-selected {
  margin-left: -11px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.annotator-marginviewer-color {
  height: 10px;
}

.annotator-hl-uber {
  background: rgba(56, 56, 56, 0.39);
  width: auto;
  display: inline;
  color: black;
}

div#annotated_content span.annotator-hl-uber-temp {
  background-color: rgba(56, 56, 56, 0.39);
  width: auto;
  display: inline;
  color: #222;
}

.annotator-marginviewer-color-1 {
  background: rgba(134, 93, 133, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-1,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-1 {
  background: rgb(134, 93, 133);
  color: white;
}

div#annotated_content .annotator-hl-color-1 {
  background: rgba(134, 93, 133, 0.3);
}

div#annotated_content .annotator-hl-uber-color-1 {
  background: rgba(134, 93, 133, 1);
  color: white;
}

div#annotated_content span.annotator-hl-uber-temp-color-1 {
  background-color: rgba(134, 93, 133, 1);
  color: white;
}

.annotator-marginviewer-color-2 {
  background: rgba(104, 157, 177, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-2,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-2 {
  background: rgb(104, 157, 177);
}

div#annotated_content .annotator-hl-color-2 {
  background: rgba(104, 157, 177, 0.3);
}

div#annotated_content .annotator-hl-uber-color-2 {
  background: rgba(104, 157, 177, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-2 {
  background-color: rgba(104, 157, 177, 1);
}

.annotator-marginviewer-color-3 {
  background: rgba(253, 210, 124, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-3,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-3 {
  background: rgb(253, 210, 124);
}

div#annotated_content .annotator-hl-color-3 {
  background: rgba(253, 210, 124, 0.3);
}

div#annotated_content .annotator-hl-uber-color-3 {
  background: rgba(253, 210, 124, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-3 {
  background-color: rgba(253, 210, 124, 1);
}

.annotator-marginviewer-color-4 {
  background: rgba(180, 200, 144, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-4,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-4 {
  background: rgb(180, 200, 144);
}

div#annotated_content .annotator-hl-color-4 {
  background: rgba(180, 200, 144, 0.3);
}

div#annotated_content .annotator-hl-uber-color-4 {
  background: rgba(180, 200, 144, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-4 {
  background-color: rgba(180, 200, 144, 1);
}

.annotator-marginviewer-color-5 {
  background: rgba(250, 184, 149, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-5,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-5 {
  background: rgb(250, 184, 149);
}

div#annotated_content .annotator-hl-color-5 {
  background: rgba(250, 184, 149, 0.3);
}

div#annotated_content .annotator-hl-uber-color-5 {
  background: rgba(250, 184, 149, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-5 {
  background-color: rgba(250, 184, 149, 1);
}

.annotator-marginviewer-color-6 {
  background: rgba(209, 131, 130, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-6,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-6 {
  background: rgb(209, 131, 130);
}

div#annotated_content .annotator-hl-color-6 {
  background: rgba(209, 131, 130, 0.3);
}

div#annotated_content .annotator-hl-uber-color-6 {
  background: rgba(209, 131, 130, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-6 {
  background-color: rgba(209, 131, 130, 1);
}

.annotator-marginviewer-color-7 {
  background: rgba(200, 150, 196, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-7,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-7 {
  background: rgb(200, 150, 196);
}

div#annotated_content .annotator-hl-color-7 {
  background: rgba(200, 150, 196, 0.3);
}

div#annotated_content .annotator-hl-uber-color-7 {
  background: rgba(200, 150, 196, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-7 {
  background-color: rgba(200, 150, 196, 1);
}

.annotator-marginviewer-color-8 {
  background: rgba(128, 192, 176, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-8,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-8 {
  background: rgb(128, 192, 176);
}

div#annotated_content .annotator-hl-color-8 {
  background: rgba(128, 192, 176, 0.3);
}

div#annotated_content .annotator-hl-uber-color-8 {
  background: rgba(128, 192, 176, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-8 {
  background-color: rgba(128, 192, 176, 1);
}

.annotator-marginviewer-color-9 {
  background: rgba(114, 127, 180, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-9,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-9 {
  background: rgb(114, 127, 180);
}

div#annotated_content .annotator-hl-color-9 {
  background: rgba(114, 127, 180, 0.3);
}

div#annotated_content .annotator-hl-uber-color-9 {
  background: rgba(114, 127, 180, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-9 {
  background-color: rgba(114, 127, 180, 1);
}

.annotator-marginviewer-color-10 {
  background: rgba(185, 185, 185, 0.3);
}

.annotator-marginviewer-selected .annotator-marginviewer-color-10,
.annotator-marginviewer-semi-selected .annotator-marginviewer-color-10 {
  background: rgb(185, 185, 185);
}

div#annotated_content .annotator-hl-color-10 {
  background: rgba(185, 185, 185, 0.3);
}

div#annotated_content .annotator-hl-uber-color-10 {
  background: rgba(185, 185, 185, 1);
}

div#annotated_content span.annotator-hl-uber-temp-color-10 {
  background-color: rgba(185, 185, 185, 1);
}

.critique-container {
  .ql-toolbar {
    background: #f8f8f8;
  }

  .ql-editor {
    background-color: #fff;
    min-height: 250px;
  }
}

.annotate-button {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 9999;
}

.margin-annotator-container {
  padding-bottom: 25px;
}