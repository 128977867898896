#membershipOptions{
    h1 {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 51px;
        color: #7f4f80;
    }
    h3 {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #555;
    }
    .profile-edit-indent {
        padding-left: 24px;
    }
    .checkbox, .radio {
        position: relative;
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .checkbox label, .radio label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
    }
    .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
        position: relative;
    }
    input[type='checkbox'] {
        margin: 4px 10px 0 0;
    }
    .form-check-input:checked {
        background-color: #7f848b;
        border-color: #7f848b;
    }
    .form-check-input:focus {
        border-color: #5a5f68;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(162 165 169 / 25%);
    }
    .daily-digest label {
        padding-left: 0;
        font-size: 16px;
        color: #7e4e7f;
        font-weight: bold;
    }
}