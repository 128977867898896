.message-compose {
  .form-group {
    margin-bottom: 1rem;
    display: flex;
    font-size: 15px!important;
  }

  .form-label {
    text-align: right;
    padding-right: 1rem;
    flex: 0 0 100px; 
  }

  .form-group > .col-sm-9 {
    flex: 1;
  }

  textarea.message-body {
    height: 300px;
    background: #efefef !important;
    border: 0 !important;
    font-size: 15px!important;
  }

  .recipient-select__multi-value{
    color: #efede9 !important;
    background: #005b7f !important;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .recipient-select__multi-value__label{
    color: #efede9 !important;
  }
}

.recipient-select__control {
  border-radius: 4px;
  background: #efefef !important;
  border: 0 !important;
  box-shadow: none !important;
  overflow: hidden !important;
}

.icon-img {
  width: 25px;
}

.view-message {
  border: solid 2px #ebebeb;
  border-radius: 5px;
  padding-left: 10px;
  padding-bottom: 10px;
  margin: 20px 0 15px 0;
}

.message-body {
  padding: 10px;
}