.rounded-gray-box {
    width: 100%;
    padding: 13px 18px;
    margin-bottom: 20px;
    background-color: #F1F1F1;

    h6 {
        font-family: 'Lato';
        color: #7F4F80;
        font-size: 18px;
        line-height: 32px;
        font-weight: 800;
    }

    p {
        font-size: 15px;
        line-height: 20px;
    }
}