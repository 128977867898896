.team {
  h4 {
    font-size: 25px;
    font-weight: 500;
    text-transform: none;
  }
  h5 {
    font-family: "Lato", sans-serif;
    font-style: italic;
    color: #555;
  }
  .founder {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .founder,
  .member {
    img {
      max-width: 100%;
    }
    hr {
      max-width: 50px;
    }
  }
  .member {
    margin-bottom: 20px;
  }
}
