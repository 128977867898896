.ink-pricing-view {
  h1 {
    margin-bottom: 10px;
    /* text-transform: none; */
  }

  h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #555;
  }

  a {
    color: #0a5c7d;
  }

  .btn.yellow {
    background: #fdc52e;
    color: #000;
  }

  .btn-large {
    padding: 10px 35px;
    font-weight: 700;
  }

  .btn {
    text-decoration: none;
    background-color: #7f4c76;
    padding: 5px 30px;
    color: #ffffff;
  }

  #pricing > div > div > div > div.pricing > div > a > div,
  body > div.container > div > div > a.plan > div > div.price,
  body > div.container > div > div > a.plan > div > div > div.price {
      font-family: 'Open Sans';
      font-weight: bold;
      font-size: 18px;
      display: inline-block !important;
  }

  .pricing {
      display: block;
      padding: 0 50px 50px;
      position: relative;
      text-align: center;
  }

  .pricing h1 {
      /*color: #663366;
      font-family: 'Lato',sans-serif;
      font-size: 48px;
      font-weight: 900;
      line-height: 34px;*/
      margin-bottom: 10px;
      /*text-transform: none;*/
  }

  /*.pricing h2 {*/
  /*font: bold 18px 'Open Sans';*/
  /*color: #333;*/
  /*}*/

  .features .intro-text {
      color: #333333;
      font-family: 'Open Sans', sans-serif;
      font-size: 22px;
      font-weight: bold;
      line-height: 30px;
  }

  .features ul {
      font-family: 'Open Sans', sans-serif;
      font-size: 15px;
      line-height: 22px;
      color: #333;
      margin: 0;
      padding-left: 40px;
  }

  .pricing input[type=text], .pricing input[type=password], .pricing input[type=email] {
      background: #efede9;
  }

  .pricing .plan {
      border: 6px solid #efede9;
      border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      width: 250px;
      display: inline-block;
      text-align: center;
      padding: 10px 5px 5px;
      position: relative;
      color: #333;
      margin: 0 15px;
  }

  .pricing .plan:hover, .pricing .plan:focus, .pricing .plan.selected {
      text-decoration: none;
      border-color: #154f66;
  }

  /*.pricing-view h2 {*/
  /*margin-bottom: 10px;*/
  /*}*/

  .pricing-view ul {
      color: #65605f;
      margin-bottom: 10px;
  }

  .pricing .plan h4 {
      font: bold 18px 'Open Sans';
      color: #333;
  }

  .pricing .plan p {
      color: #222;
  }

  .pricing .plan .amount {
      border-top: 6px solid #efede9;
      color: #333 !important;
      /*font: bold 18px 'Open Sans';*/
      font-weight: bold;
      padding: 10px 0 0 0
  }

  .pricing .plan:hover .amount, .pricing .plan:focus .amount, .pricing .plan.selected .amount {
      border-color: #154f66;
  }

  .pricing .plan .ico-selected {
      width: 24px;
      height: 24px;
      display: none;
      background: url('../../styles/img/sprite.png') -500px -100px;
  }

  .pricing .plan:hover .ico-selected, .pricing .plan:focus .ico-selected, .pricing .plan.selected .ico-selected {
      display: inline-block !important;
      position: absolute;
      top: -15px;
      left: 110px;
  }

  .pricing .ico-help {
      width: 24px;
      height: 24px;
      display: inline-block;
      position: absolute;
      left: 15px;
      margin-top: 2px;
      background: url('../../styles/img/sprite.png') -500px -150px;
  }

  .pricing .select-plan {
      padding: 30px 0
  }
}