.login {
  margin-top: 40px;
  .forgot-pass{
    width: 100%;
    label {
      width: 100%;
    }
    span {
      margin-right: 30px;
    }
  }
  form {
    margin-bottom: 15px;
  }
  button {
    padding: 5px 30px;
  }
}
