.ink-home {
  height: 500px !important;
}

.container.home {
  padding: 30px 70px 0px 70px;
  h1.header {
    color: #7f4f80;
    font-size: 42px;
    line-height: 40px;
    font-weight: 700;
    text-transform: none;
    text-align: center;
    margin-bottom: 15px;
  }
  h1.sub-title {
    font-size: 28px;
    font-family: "Lato", sans-serif;
    line-height: 40px;
    font-weight: 900;
    text-transform: none;
    color: #555;
    font-style: italic;
    text-align: center;
    margin-bottom: 50px;
  }
  img.laptop-drawing {
    max-width: 100%;
    max-height: 320px;
    margin-bottom: 50px;
  }
  img.home-birds {
    object-position: 0px -70px;
    max-width: 100%;
    max-height: 350px;
    margin-bottom: 0px;
  }
  .home-bird-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
    margin-right: 0;
  }
  img.home-bird {
    max-width: 70%;
    max-height: 180px;
    margin-bottom: 180px;
    margin-top: auto;
  }
  h4 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #555;
    margin-bottom: 15px;
    font-style: normal;
    text-transform: none;
  }
  .purpleTextHighlight {
    color: #7e4e7f !important;
  }
  a {
    color: #0a5c7d;
  }
  a.btn.purple {
    color: #fff;
  }
  .educators-link {
    font-weight: 700;
  }
  h2.separator {
    margin-bottom: 30px !important;
    font-family: "Lato", sans-serif;
    font-weight: 600 !important;
    font-size: 36px !important;
    line-height: 51px;
    color: #7f4f80;
    font-style: normal;
    text-transform: none;
    border: 0 none;
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-align: center;
  }
  h2.separator::before {
    background-color: #7f4f80;
    content: "";
    display: inline-block;
    height: 1px;
    margin: 0 0.5em 0 -55%;
    vertical-align: middle;
    width: 50%;
  }
  h2.separator::after {
    background-color: #7f4f80;
    content: "";
    display: inline-block;
    height: 1px;
    margin: 0 -55% 0 0.5em;
    vertical-align: middle;
    width: 50%;
  }
  .card-container {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 28px;
      font-family: "Lato", sans-serif;
      line-height: 40px;
      font-weight: 900;
      text-transform: none;
      color: #555;
      font-style: italic;
      text-align: center;
      margin-bottom: 15px;
    }

    p {
      font-weight: 400;
      padding: 0 25px 0 25px;
    }

    .card-footer-text {
      font-style: italic;
    }
  }
  .what-youll-find-container {
    margin-bottom: 40px;
  }
  .what-it-like-container {
    margin-bottom: 40px;
  }
  .img-writing-groups {
    width: 300px;
    height: 170px;
    margin-bottom: 10px;
  }
  .img-workshops {
    width: 180px;
    height: 170px;
    margin-bottom: 10px;
  }
  .img-community-initiatives {
    width: 300px;
    height: 170px;
    margin-bottom: 10px;
  }
  .img-laptop-drawing {
    width: 210px;
    height: 170px;
    margin-bottom: 10px;
    margin-left: 20px;
  }
  h3 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 20px;
    color: #555;
    line-height: 35px;
  }
  h3.how-it-works {
    text-align: center;
  }
  .center {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  img.how-it-works {
    margin-top: 50px;
    margin-bottom: 23px;
    max-width: 100%;
    height: auto;
  }
  img#critique-document.how-it-works {
    margin-top: 29px;
  }
  h4.how-it-works,
  p.how-it-works {
    text-align: center;
  }
  .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
  }
  .embed-responsive-16by9 {
    padding-bottom: 56.25%;
  }
  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .learn-more-img {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 50px 0 50px;
    a.home-bird {
      width: 70px;
      padding: 0;
    }
    h4 {
      line-height: 25px;
    }
    img {
      width: 70px;
      height: 40px;
      margin: 0 0 40px 0;
      display: block;
    }
    a.home-bird img {
      margin-left: 15px;
    }
  }
  .testimonial-container {
    padding: 0 5px 5px 25px;
  }
  .testimonial {
    padding: 15px 0px 0px 0px;
  }
  .testimonial-sample-text {
    position: relative;
    bottom: 10px;
  }
  .testimonial-sample {
    color: #608d83;
    font-family: "lato", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    font-style: italic;
    margin-top: 20px;
  }
  .quotation-mark {
    font-size: 83px;
    font-weight: 900;
    float: left;
    position: relative;
    margin-right: 10px;
    left: 10px;
  }
  .testimonial-link {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #0f6183;
    line-height: 26px;
    font-weight: 700;
    font-style: normal;
    padding-top: 15px;
    a {
      color: #0a5c7d;
    }
  }
  .testimonial-signature {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    font-style: normal;
    padding-top: 15px;
    padding-left: 25px;
    text-align: start;
  }
  h5 {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    color: #7f4f80;
    line-height: 27.5px;
    font-weight: 400;
    text-transform: uppercase;
  }
  h6 {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #362e35;
    line-height: 26px;
    font-weight: 700;
  }
  .img-responsive {
    max-width: 100%;
  }
  .italic {
    font-style: italic;
  }
  .trial-section {
    text-align: center;
    margin-top: 95px;
    vertical-align: middle;
    .btn {
      margin-bottom: 15px;
      margin-top: 15px;
      padding-bottom: 5px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 5px;
      h4 {
        font-family: "Open Sans", sans-serif;
        color: #000;
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .container.home {
    img.laptop-drawing {
      margin-bottom: 40px;
    }
    img.home-birds {
      margin-left: 0;
    }
    h3.how-it-works {
      margin-bottom: 40px;
    }
  }
}
.grey-background-top {
  width: 100%;
  background: #fdfafa
    url(https://www.inkedvoices.com/static/img/desktop-swirl-top.png) repeat-x
    scroll 0 0;
  height: 87px !important;
}
.home.grey-background {
  background: #fdfafa
    url(https://www.inkedvoices.com/static/img/grey-background.png) repeat
    scroll 0 0;
}
.grey-background-bottom {
  width: 100%;
  background: #fdfafa
    url(https://www.inkedvoices.com/static/img/desktop-swirl-bottom.png)
    repeat-x scroll 0 0;
  height: 89px !important;
  position: relative;
  bottom: 16px;
}
.grey-background-top.end {
  width: 100%;
  background: #fdfafa url(http://localhost:8000/static/img/last-swirl-top.png)
    repeat-x scroll 0 0;
  height: 87px !important;
}
.home.grey-background.end {
  background: #fdfafa url(http://localhost:8000/static/img/grey-background2.png)
    repeat scroll 0 0;
}
