.profile-edit {
    .row {
        padding: 0 0 25px 0
    }

    .row div.col-md-9 div.col-md-5 {
        padding-bottom: 0;
    }

    h4 {
        text-align: right;
    }

    .row.simple {
        padding: 0
    }

    label.title {
        text-align: right;
        color: #432038;
        font-size: 21px;
    }

    label {
        color: #000000;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 16px;
        font-weight: 600;
    }

    p.field-description {
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 15px;
        line-height: 21px;
    }

    div.char-count {
        float: right;
    }

    input[type=url],
    input[type=text],
    input[type=search],
    input[type=number],
    input[type=password],
    input[type=email] {
        width: 100%;
        height: 30px;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;
    }

    textarea {
        height: 5em;
        width: 100%;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;

    }

    textarea {
        height: 5em;
        width: 100%;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;

    }

    select {
        height: 30px;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;
    }

    .input-prepend input[type=text] {
        display: inline-block;
    }

    .input-append {
        margin-bottom: 0;
    }

    #id_max_words {
        background: white;
    }

    .profile-picture {
        width: 135px;
        height: 138px;
        display: inline-block;
        border: 1px solid #666666;
        padding: 2px
    }

    .btn.cancel {
        background-color: #afafaf;
        color: #ffffff;
        border: none;
    }

    .ico-help {
        width: 32px;
        height: 32px;
        background: url('../../../styles/img/sprite.png') -500px -150px;
        display: inline-block;
        position: relative;
        right: 0;
        border: 0;
    }

    span.select2-container {
        width: 100% !important;
    }

    span.select2-selection {
        height: 100%;
        background: #ebebeb;
        border-color: #ebebeb !important;
    }

    .personal-information input {
        margin-bottom: 0px;
    }

    .level-0 {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #555;

        input[type="checkbox"]  {
            display: none;
        }
    }

    .delete-row, .add-row {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        color: #0a5c7d;
    }
    .level-0 {
        background-color: rgb(235, 235, 235);
    }

    .custom-checkbox li {
        list-style: None;
        margin-bottom: 0px;
    }

    .custom-checkbox label {
        font-weight: normal;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 15px;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li input[type=text] {
        margin: 0 0 0 30px
    }

    @media (min-width: 1200px) {
        .container {
            width: 1070px;
        }
    }

    .horizontal-align {
        display: flex;
        justify-content: space-between;
    }

    .profile-edit-indent {
        padding-left: 24px;
    }

    i {
        text-align: right;
        float: right;
    }

    .horizontal-align {
        display: flex;
    }

    .group-profile-picture {
        width: 152px;
        height: 138px;
        background: url(../../../assets/bg-group-profile-picture.png) 50% 50% no-repeat;
        display: inline-block;
        padding: 2px;
    }

}

.bottom-edge-white {
    height: 15px;
    background: url(../../../assets/bg-edge-white.png) 50% bottom repeat-x;
}

.bottom-edge-light {
    height: 15px;
    background: #f4f3f0 url(../../../assets/bg-edge-light.png) 50% bottom repeat-x;
}

.prefooter-one {
    background: url(../../../assets/howItWorks-bg.png) #F4F3F0;
}

.profile-edit input[type=url], .profile-edit input[type=text], .profile-edit input[type=search], .profile-edit input[type=number], .profile-edit input[type=password], .profile-edit input[type=email], .message-compose input[type=text], .message-compose textarea {
    width: 100%;
    height: 30px;
    background: #ebebeb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
}

#id_critique_estimate, #id_max_words, #id_critique_time_window, #id_review_time_window, #id_min_size, #id_max_size, #id_min_critique_words {
    width: 60px;
    padding-right: 2px;
    margin-right: 4px;
}

.prefooter-two {
    background: url(../../../assets/eightReasons-bg.png) #E3DFD2;
}
