.ink-archive {
    padding-bottom: 20px;

    h1 {
        text-align: center;
        font-weight: 700;
    }

    h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        .highlight {
            font-weight: 800;
            color: #7e4e7f !important;
        }
    }

    .empty {
        min-height: 100px;
        padding-top: 40px;
        padding-left: 80px;
    }

    .archive-items-list {
        padding-top: 20px;

        .archive-item {
            padding: 10px;
            margin-bottom: 25px;

            h4 {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 22px;
                color: #0A5C7D;
            }

            .archive-item-img-container {
                height: 180px; 
                width: 100%; 
                overflow: hidden;
            }

            .archive-item-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .left-column {
                text-align: center;

                .month {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 42px;
                }
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 17px;
            }

            .description-content {
                max-height: none; 
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 10; 
                overflow: hidden;
                font-size: 15px;
                line-height: 20px;
                font-family: "Open Sans",sans-serif;

                * {
                    font-size: 15px;
                    line-height: 20px;
                    font-family: "Open Sans",sans-serif;
                }
            }
        }
    }
}