.ink-testimonials {
  width: 100%;

  img {
    border-radius: 50%;
    height: 90px;
    width: 90px;
    margin: 20px 0;
  }

  .bold {
    font-weight: bold;
  }
}
