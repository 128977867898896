.critique-card {
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 13px;
  line-height: 27px;

  h3 {
    margin-bottom: 30px;
    padding-top: 10px;
    height: 25px;
    a {
      color: #0a5c7d;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
    }
  }
  span.date {
    margin-bottom: 10px;
  }
  img {
    float: left;
    width: 65px;
    height: 65px;
    margin-top: 10px;
    margin-right: 10px;
  }
  p {
    font-family: "Open Sans", sans-serif;
    color: #000;
    font-size: 13px;
    line-height: 27px;
  }
}
