@import "styles/variables";

.ink-calendar {

  @media (min-width: 768px) {}

  .img-container {
    padding-bottom: 25px;
  }

  &__content {
    min-height: 400px;
    align-items: center;

    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #000000;
    }

    h5 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 800;
    }

    p.grey {
      font-size: 18px;
      color: #555555;
    }

    span.accent {
      font-size: 18px;
      font-weight: 700;
      color: #7e4e7f;
    }

    img.laptop-drawing {
      max-width: 277px;
    }

    .button-list {
      line-height: 40px;

      button {
        margin-right: 3px;
      }
    }

    .category-container {
      padding-right: 0;
      margin-bottom: 10px;
    }

    .type-desc-container {
      padding-bottom: 20px;

      .type-desc {
        span.name {
          font-weight: 700;
        }

        font-style: italic;
        color: #555555;
        font-size: 15px;
        line-height: 27px;
        margin-bottom: 0px;
      }
    }

    table {
      margin-bottom: 30px;

      th {
        background: #F1F1F1;
        text-transform: uppercase;
        border-color: #FFFFFF;
        border-width: 1px;
      }

      tr {
        border-color: #000000;
      }

      tr:hover {
        background-color: #0a5c7d23;
      }
    }

    td.event-type {
      text-transform: uppercase;
      color: #555555;
      font-weight: 700;
    }

    td.event-title a {
      font-weight: 600;
      color: #7e4e7f;
    }
  }

  .calendar-row {
    background-color: #ebebeb;
    margin: 6px 0;
  }


  button.purple,
  .btn.purple:hover,
  .btn.purple:focus {
    background: #7e4e7f !important;
    box-shadow: none;
    font-size: 15px;
    line-height: 27px;
    padding: 3px 8px;
  }

  button.purple.inactive,
  button.purple.inactive:hover,
  button.purple.inactive:focus {
    background: #D9D9D9 !important;
    color: #555555;
    box-shadow: none;
    font-size: 15px;
    line-height: 27px;
    padding: 3px 8px;
  }

  label {
    font-size: 13px;
    line-height: 14px;
  }
}