.group-members-list.public li.item {
    border: 2px solid #efefef;
    padding: 6px;
    text-align: left;
    border-radius: 3px;
}

.group-members-list.public #groupMembersList {
    margin: 0;
    padding-left: 0;
}

.group-view #groupMembersList {
    margin: 10px 0 0 0 !important;
}

.group-members-list li {
    list-style: none;
    width: 48%;
    display: inline-block;
    min-height: 60px;
    margin-bottom: 5px;
    text-align: center;
    vertical-align: top;
    margin-right: 5px;
}

li {
    font-family: 'Open Sans', sans-serif;
    color: #000;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 27px;
}

.group-members-list li a {
    color: #51235e!important;
}

.group-members-list.public li img {
    display: inline-block;
    width: 69px;
    height: 69px;
    margin-bottom: 0;
}

.group-members-list.public ul {
    margin-top: 8px;
}

.group-members-list li p, .group-members-list li a {
    font-size: 12px!important;
    line-height: 13px!important;
    margin: 0!important;
    padding: 0!important;
    text-decoration: none!important;
}