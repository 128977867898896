.nav-tabs .nav-link {
  background-color: #8c7a8b;
  color: white;
}

.nav-tabs .nav-link.active {
  background-color: white;
  color: #8c7a8b;
}

.nav-tabs .nav-link:hover {
  background-color: #7e4e7f;
  color: white;
}

.nav-tabs .nav-link.active:hover {
  background-color: white;
  color: #8c7a8b;
}