.ink-profile-edit {
    .row {
        padding: 0 0 25px 0
    }
    
    .row div.col-md-9 div.col-md-5 {
        padding-bottom: 0;
    }
    
    h4 {
        text-align: right;
    }
    
    .row.simple {
        padding: 0
    }
    
    label.title {
        text-align: right;
        color: #432038;
        font-size: 21px;
    }
    
    label {
        color: #000000;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 16px;
        font-weight: 600;
    }
    
    p.field-description {
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 15px;
        line-height: 21px;
    }
    
    div.char-count {
        float: right;
    }
    
    i {
        text-align: right;
        float: right;
    }
    
    ul {
        padding: 0;
        margin: 0;
    }
    
    li input[type=text] {
        margin: 0 0 0 30px
    }

    input[type=url],
    input[type=text],
    input[type=search],
    input[type=number],
    input[type=password],
    input[type=email] {
        width: 100%;
        height: 30px;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;
    }

    textarea {
        height: 5em;
        width: 100%;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;
    
    }

    textarea {
        height: 5em;
        width: 100%;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;
    
    }

    select {
        height: 30px;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        display: block;
        margin-bottom: 10px;
    }

    .input-prepend input[type=text] {
        display: inline-block;
    }

    .input-append {
        margin-bottom: 0;
    }

    #id_max_words {
        background: white;
    }
    
    .custom-checkbox li {
        list-style: None;
        margin-bottom: 0px;
    }
    
    .custom-checkbox label {
        font-weight: normal;
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 15px;
    }

    .profile-picture {
        width: 135px;
        height: 138px;
        display: inline-block;
        border: 1px solid #666666;
        padding: 2px
    }

    .btn {
        text-decoration: none;
        background-color: #7f4c76;
        padding: 5px 30px;
        color: #ffffff;
    }

    .btn.cancel {
        background-color: #afafaf;
        color: #ffffff;
        border: none;
    }

    .btn.purple, .btn-primary {
        background: #7e4e7f;
        color: #fff;
        text-shadow: none;
        border: none;
    }

    .ico-help {
        width: 32px;
        height: 32px;
        background: url('../../../styles/img/sprite.png') -500px -150px;
        display: inline-block;
        position: relative;
        right: 0;
        border: 0;

        // .btn, .btn-primary, .btn-primary:hover, .btn:hover{
        //     background: none !important;
        //     border: none;
        //     background: none;
        //     padding: 0;
        //     margin: 0;
        //     color: none !important;
        //     padding: 0;
        // }
    }
    
    span.select2-container {
        width: 100% !important;
    }
    
    span.select2-selection {
        height: 100%;
        background: #ebebeb;
        border-color: #ebebeb !important;
    }
    
    .personal-information input {
        margin-bottom: 0px;
    }

    .level-0 {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #555;

        input[type="checkbox"]  {
            display: none;
        }
    }

    .delete-row, .add-row {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        color: #0a5c7d;
    }
    .level-0 {
        background-color: rgb(235, 235, 235);
    }
}

.horizontal-align {
    display: flex;
    justify-content: space-between;
}

.profile-edit-indent {
    padding-left: 24px;
}

