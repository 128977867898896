.testimonial {
    width: 100%;
    padding: 13px 18px;
    margin-bottom: 20px;
    background-color: rgba(96, 141, 131, 0.2);
    color: #608D83;
    font-family: 'Lato';
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    line-height: 35px;
    padding: 15px;

    .quote {
        font-size: 83px;
        line-height: 0px;
        top: 40px;
        position: relative;
        margin-right: 5px;
    }

    .name {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27.5px;
        color: #555555;
    }
}