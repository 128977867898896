.ink-groups {
  height: 500px;
}

.sidebar {
  border: 0;

  .group-picture {
    width: 100%;
    margin-bottom: 15px;
    max-width: 100%;
    aspect-ratio: 1 !important;
  }

  div.section {
    margin-top: 15px;
    margin-bottom: 0;

    h3.subt {
      color: #000;
      font: bold 14px "Open Sans";
      margin-bottom: 0;

      a {
        color: #0a5c7d;
      }
    }
  }

  .group-members-list {
    h3.subt {
      margin-bottom: 5px !important;
    }

    .text-sm {
      font-size: 12px;
      line-height: 13px;
      margin: 0;
      padding: 0;
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
      color: #000;
      text-align: center;
      margin-bottom: 5px;
      padding-top: 2px;
    }
  }
}

.nav.nav-tabs {
  .tab-purple {
    background-color: #8c7a8b;
    color: #ffffff;

    &.active {
      background-color: #ffffff;
      color: #8c7a8b;
    }
  }
}

div#settings {
  li {
    font-size: 13px;
  }
}

.tab-content {
  table {
    table-layout: fixed;

    th,
    td {
      font-size: 11.5px;
    }

    td {
      border-bottom: 2px solid #efefef;
      vertical-align: middle;

      .btn {
        font-size: 11.5px;
        padding: 4px 10px;
      }

      .critique-icon {
        background-image: url("https://www.inkedvoices.com/static/img/glasses.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 30px;
        height: 29px;
      }

      a.cutoff {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    th {
      background-color: rgba(91, 58, 85, 0.3) !important;
      color: #432038;
      text-transform: uppercase;
      border-bottom: solid 1px #330033;
    }

    td.purple-row {
      background: #cec4cc;
      border: none;
    }
  }
}