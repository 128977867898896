div.dropdown-multiselect-wrapper {
  width: 200px;
  .dropdown-multiselect-button {
    font-size: 14px;
    font-weight: 400;
    font-family: arial, verdana, sans-serif;
    width: 200px;
    text-decoration: none;
    background-color: #7f4c76;
    padding: 5px 30px;
    color: #ffffff;
    border: 0;
    box-shadow: none !important;
    button {
      width: 200px;
    }
  }
  .dropdown-multiselect-button.open.btn {
    width: 200px;
    min-width: 200px;
    background-color: #e2e2e2;
    color: #000;
    &:hover {
      background-color: #e2e2e2 !important;
      color: #000;
    }
  }
  div.dropdown-multiselect-options {
    position: absolute;
    background-color: #ffffff;
    margin-top: 1px;
    width: 200px;
    max-width: 200px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.15);
    border-style: solid;
    border-radius: 5px;
    z-index: 1000;
    ul {
      max-width: 200px;
      padding: 0%;
      margin: 0%;
      li {
        list-style: none;
        line-height: 12px;
        padding: 5px 10px;
        cursor: pointer;
        &:hover {
          background-color: #7f4c7622;
        }
        margin: 0;
        margin-bottom: 15px;
        input {
          float: left;
          margin-top: 4px;
        }
        span {
          width: 150px;
          padding-left: 5px;
          // elipsis;
          overflow-x: hidden;
          overflow-y: visible;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          line-height: 20px;
          text-align: left;
        }
      }
    }
  }
}
