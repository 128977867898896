.ink-group-admin{
    .btn {
        text-decoration: none;
        background-color: #7f4c76;
        padding: 5px 30px;
        color: #ffffff;
    }
    .btn.purple, .btn-primary {
        background: #7e4e7f;
        color: #fff;
        text-shadow: none;
        border: none;
    }
    .add-margin {
        margin-bottom: 10px;
    }
    table {
        margin-top: 10px;
        margin-bottom: 5px !important;
    }
    .table th {
        background: #7f4c76 !important;
        color: #FFF;
    }
    .table td {
        background: #f9f9f9;
        border-bottom: 1px solid #FFF;
    }
    .table a {
        color: #222;
        text-decoration: none;
    }
}