.dashboard {
  h2#groups_title,
  h2#projects_title {
    font-family: "Lato", sans-serif;
    font-weight: bold !important;
    font-style: normal;
    font-size: 24px !important;
    color: #6f6867;
    line-height: 34px;
    .ico-help {
      width: 32px;
      height: 32px;
      background: url(https://www.inkedvoices.com/static/img/sprite.png) -500px -150px;
      display: inline-block;
      position: relative;
      right: 0;
    }
  }
  .groups-list {
    .group,
    .invite{
      width: 46%;
      display: inline-block;
      border: 2px solid #efefef;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      vertical-align: top;
      margin: 5px;
      padding: 0;
    }
    .group {
      .group-container {
        padding: 18px 15px 10px 15px;
      }
      h2 {
        a{
          color: #663366;
          font-size: 18px !important;
          font-style: italic;
        }
        img{
          width: 20px;
          cursor: pointer;
        }
      }
      .credits {
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        font-family: "Open Sans", sans-serif;
        color: #000;
        margin-bottom: 15px;
        font-size: 15px;
        line-height: 27px;
      }
      h5{
        font: bold 12px 'Lato';
        font-style: italic;
        color: #333;
        margin: 0;
      }
      ul{
        list-style-position: outside;
        list-style-image: url(https://www.inkedvoices.com/static/img/bullet.png);
        color: #432038;
        font-size: 11px;
        margin: 0 0 0 0 !important;
        padding-left: 16px;
        li{
          display: none;
          font-family: 'Open Sans', sans-serif;
          color: #000;
          margin-bottom: 15px;
          font-size: 15px;
          line-height: 27px;
          span.icon-note-cancel{
            background: url(https://www.inkedvoices.com/static/img/sprite.png) -200px -50px;
            zoom: 0.7;
            width: 16px;
            height: 16px;
            cursor: pointer;
            margin-left: 5px;
            display: inline-block;
          }
        }
        li:first-child{
          display: list-item;
        }
      }
    }
    img.avatar {
      max-width: 100%;
      border: none;
      width: 94px;
      height: 94px;
      display: block;
    }
    .invite {
      background: #ebebeb;
      text-align: center;
      color: #c9c9c9;
      padding: 5px;
      .invite-container{
        padding: 18px 15px 10px 15px;
        .intro {
          color: #333;
          font: bold 18px 'Lato';
        }
        h2.title{
          font-family: 'Lato', sans-serif;
          font-weight: bold;
          font-style: normal;
          font-size: 24px;
          line-height: 34px;
          color: #333 !important;
          border-top: 2px solid #333;
          border-bottom: 2px solid #333;
          padding: 7px 0;
          margin-bottom: 15px;
          font: bold 18px 'Lato';
        }
        .btn-container{
          margin-top: 15px;
        }
        a{
          text-decoration: none;
          color: #0a5c7d;
          .btn-block.btn.btn-primary{
            color: #fff;
            text-shadow: none;
            border: none;
            display: block;
            width: 100%;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            user-select: none;
            border-radius: 4px;
          }
          .grey{
            background: #afafaf;
            color: #ffffff;
          }
          .grey:hover{
            background: #707070 !important;
          }
        }
      }
    }
  }
}
.group-admin-tooltip{
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  li{
    a{
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333;
      white-space: normal !important;
      text-decoration: none;
    }
    a:hover {
      color: #2b967c;
    }
  }
}
