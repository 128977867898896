.group-tab {
  label {
    color: #000000;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
  }
}
.purple-link {
    color: #7e4e7f;
    border: none;
    background-color: #ffffff00;
    padding: 0 10px;
}