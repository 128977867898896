.pagination-buttons {
  a {
    color: black;
  }
  menu,
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
