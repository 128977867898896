.ink-faq {
  width: 100%;

  .answer {
    padding-left: 30px;
  }

  h3 {
      color: #663366;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
  }

  h2 {
      margin: 10px 0;
  }

  ol {
      padding-left: 50px;
  }
}
