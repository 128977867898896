.ink-code-of-conduct {
  width: 100%;

  .small_h1 {
    font-size: 30px;
  }

  .hide-numbers {
    list-style: none;
  }

  li {
    font-family: 'Open Sans', sans-serif;
    color: #000;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 27px;
}
}
